import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { FormControl, Grid, InputLabel, makeStyles, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Controller, useForm } from 'react-hook-form';
import uploadingAnimation from '../lotties/uploading.json';
import { storeMetadata } from '../db';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '100%',
    textAlign: 'center',
  },
  selectbox: {
    width: '100%',
  },
}));

const ACCENTS_GROUPS_KEYS = {
  INDIA: `IN`,
  OTHERS: `OTHERS`,
  PH: `PH`,
  LATAM: `LATAM`,
};

const ACCENTS_GROUPS = [
  { label: `India`,key: ACCENTS_GROUPS_KEYS.INDIA },
  { label: `Others`,key: ACCENTS_GROUPS_KEYS.OTHERS },
  { label: `Philippines`,key: ACCENTS_GROUPS_KEYS.PH },
  { label: `Latam`,key: ACCENTS_GROUPS_KEYS.LATAM },
];

const PH_ACCENTS = [
  `Tagalog`,
  `Cebuano`,
  `Ilocano`,
  `Hiligaynon`,
  `Bicolano`,
  `Waray`,
  `Pampango`,
  `Pangasinense`,
];

const OTHER_ACCENTS = [
  `American`,
];

const INDIAN_ACCENTS = [
  `Adi`,
  `Apatani`,
  `Assamese`,
  `Bengali (Bangla)`,
  `Bodo`,
  `Dogri`,
  `Galo`,
  `Garo`,
  `Gujarati`,
  `Hindi`,
  `Kannada`,
  `Karbi`,
  `Kashmiri`,
  `Khasi`,
  `Kokborok`,
  `Konkani`,
  `Maithili`,
  `Malayalam`,
  `Marathi`,
  `Meitei (Manipuri)`,
  `Mizo`,
  `Nagamese`,
  `Nepali`,
  `Nyishi`,
  `Odia`,
  `Other`,
  `Punjabi`,
  `Sanskrit`,
  `Santali`,
  `Sindhi`,
  `Tamil`,
  `Telugu`,
  `Urdu`,
];

const LATAM_ACCENTS = [
  `AntiguaAndBarbuda`,
  `Argentina`,
  `Bolivia`,
  `Brazil`,
  `Chile`,
  `Colombia`,
  `CostaRica`,
  `Cuba`,
  `DominicanRepublic`,
  `Ecuador`,
  `ElSalvador`,
  `FrenchGuiana`,
  `Guadeloupe`,
  `Guatemala`,
  `Haiti`,
  `Honduras`,
  `Martinique`,
  `Mexico`,
  `Nicaragua`,
  `Panama`,
  `Paraguay`,
  `Peru`,
  `PuertoRico`,
  `SaintBarthélemy`,
  `SaintLucia`,
  `SaintMartin`,
  `Uruguay`,
  `Venezuela`,
];

const ACCENTS = {
  [ACCENTS_GROUPS_KEYS.INDIA]: INDIAN_ACCENTS,
  [ACCENTS_GROUPS_KEYS.OTHERS]: OTHER_ACCENTS,
  [ACCENTS_GROUPS_KEYS.PH]: PH_ACCENTS,
  [ACCENTS_GROUPS_KEYS.LATAM]: LATAM_ACCENTS,
};

export default function MetadataForm({ onNext }) {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const {handleSubmit,reset,watch,control } = useForm();

  const [accentsInView,setAccentsInView] = useState([]);
  const [firstname,setFirstname] = useState();
  const [lastname,setLastname] = useState();
  const [accent,setAccent] = useState();
  const [age,setAge] = useState();
  const [gender,setGender] = useState();
  const [country, setCountry] = useState();

  useEffect(() => {
    const subscription = watch((value,{ country }) => {
      if (value.country){
        setAccentsInView(ACCENTS[value.country]);
        setCountry(value.country)
      };
      if(value.accent) setAccent(value.accent);
      if(value.age) setAge(value.age);
      if(value.gender) setGender(value.gender);
    });
    return () => subscription.unsubscribe();
  },[watch]);

  const onSubmit = async (d) => {
    setSaving(true);
    const data = { ...d,firstname,lastname,accent,gender,age,country  };

    await storeMetadata(data);
    setSaving(false);
    onNext(d);
  };

  const handleFirstNameChange = evt => {
    const val = evt.target.value.replace(/[^\w\s]/gi,``);
    reset({ firstname: val });
    setFirstname(val);
  };

  const handleLastNameChange = evt => {
    const val = evt.target.value.replace(/[^\w\s]/gi,``);
    reset({ lastname: val });
    setLastname(val);
  };

  if (saving) {
    return (
      <>
        <Container maxWidth="sm">
          <Box my={4}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: uploadingAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={400}
              width={400}
            />
            <div className={classes.submit}>Saving your information...</div>
          </Box>
        </Container>
      </>
    );
  }

  return (
    <form id="metadata-form" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Controller
            name="firstname"
            control={control}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="First Name"
                autoComplete="firstname"
                onKeyUp={handleFirstNameChange}
                autoFocus
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name="lastname"
            control={control}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Last Name"
                onKeyUp={handleLastNameChange}
                autoComplete="lastname"
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <FormControl className={classes.selectbox}>
                <InputLabel htmlFor="gender">Gender</InputLabel>
                <Select
                  native
                  required
                  inputProps={{
                    name: 'gender',
                    id: 'gender',
                  }}
                  {...field}
                >
                  <option aria-label="None" value="" />
                  <option>Male</option>
                  <option>Female</option>
                  <option>Other</option>
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Controller
            name="age"
            control={control}
            render={({ field }) => (
              <FormControl className={classes.selectbox}>
                <InputLabel htmlFor="age">Age</InputLabel>
                <Select
                  native
                  required
                  inputProps={{
                    name: 'age',
                    id: 'age',
                  }}
                  {...field}
                >
                  <option aria-label="None" value="" />
                  <option>18-30</option>
                  <option>30-50</option>
                  <option>50+</option>
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <FormControl className={classes.selectbox}>
                <InputLabel htmlFor="country">Country</InputLabel>
                <Select
                  native
                  required
                  inputProps={{
                    name: `country`,
                    id: `country`,
                  }}
                  {...field}>
                  <option aria-label="None" value="" />
                  {ACCENTS_GROUPS.map((accent_group,index) => {
                    return (<option key={accent_group.key} value={accent_group.key}>{accent_group.label}</option>);
                  })}
                </Select>
              </FormControl>
            )}/>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Controller
            name="accent"
            control={control}
            render={({ field }) => (
              <FormControl className={classes.selectbox}>
                <InputLabel htmlFor="accent">Dialect</InputLabel>
                <Select
                  native
                  required
                  inputProps={{
                    name: `accent`,
                    id: `accent`,
                  }}
                  {...field}>
                  <option aria-label="None" value="" />
                  {accentsInView.map((accent,index) => {
                    return (<option key={accent} value={accent.split(` `)[0]}>{accent}</option>);
                  })}
                </Select>
              </FormControl>
            )}/>
        </Grid>
      </Grid>

      <div className={classes.submit}>
        <Button type="submit" variant="contained" color="primary" id="metadata-next">
          Next
        </Button>
      </div>
    </form>
  );
}
